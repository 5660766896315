// @flow
import React, { type Node } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { Box } from "@mui/material";
import { AddContent } from "@fas/ui-core";
import keyGenerator from "weak-key";
import MessageAction from "../../components/MessageAction";
import type { Action } from "./CreateMessage.types";

type Props = {
  value?: Array<Action>,
  onChange?: (Array<Action>) => void,
}

export const emptyAction = {
  title: "",
  action: "",
  ifAppendToClickUrl: true,
  isActive: true,
};

function ActionsForm({
  value = [],
  onChange,
}: Props): Node {
  const addAction = () => {
    onChange && onChange([...value, { ...emptyAction }]);
  };

  const removeAction = (index) => {
    onChange && onChange(value.filter((_, idx) => idx !== index));
  };

  const changeAction = ({ title, value: newValue, index }) => {
    const actions = [...value];
    actions[index][title] = newValue;
    onChange && onChange(actions);
  };

  return (
    <>
      {value.map((item, index) => (
        <Box key={keyGenerator(item)} mb={2} justifyContent="center" alignItems="center">
          <MessageAction
            index={index}
            action={item}
            handleChangeActionsFields={changeAction}
            handleRemoveAction={removeAction}
          />
        </Box>
      ))}
      <Box width={1} justifyContent="center" alignItems="center">
        <AddContent
          title="+ ADD ACTION"
          onTitileClick={addAction}
          onArrowClick={addAction}
          isAddDisabled={value.length >= 2}
        />
      </Box>
    </>
  );
}

export default withFormField(ActionsForm);
