/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect, useState, type Node,
} from "react";
import { Typography } from "@mui/material";
import { SlideModal, AddContent, GridUc } from "@fas/ui-core";
import TableActions from "../../containers/StepTableActions";
import MoivationContentSetupList from "../MotivationContentSetupList";
import ExternalRedirect from "../App/ExternalRedirect";
import type { UseState } from "../../helpers/common.types";

export type Props = {
  ListComponent: *,
  value?: *[],
  onChange?: (*[]) => mixed,
  title: string,
  listName: string,
  multiple?: boolean,
  openOnEmpty?: boolean,
  ViewList?: *,
  error?: string,
};

function ListModalSetup({
  ListComponent,
  value = [],
  onChange,
  title,
  listName,
  multiple = true,
  openOnEmpty = false,
  ViewList = MoivationContentSetupList,
  error = "",
}: Props): Node {
  const [isModalOpen, setIsModalOpen]: UseState<boolean> = useState(openOnEmpty ? !value.length : false);
  const [selectedMotivationContents, setSelectedMotivationContents]: UseState<*[]> = useState([]);

  useEffect(() => {
    setSelectedMotivationContents([...value]);
  }, [value]);

  return (
    <>
      <GridUc
        item
        xs={8}
        mb={value.length ? 0 : 3}
      >
        {error && <Typography align="center" color="error">{error}</Typography>}
        <AddContent
          title={`+ ${title}`}
          onTitileClick={(): void => setIsModalOpen(true)}
          onArrowClick={(): void => setIsModalOpen(true)}
        />
        <SlideModal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onSave={() => {
            setIsModalOpen(false);
            onChange && onChange(selectedMotivationContents);
          }}
          title={title}
          dataTestid={`modal-${listName}`}
          content={(
            <ListComponent
              choicedData={selectedMotivationContents}
              Actions={(
                <TableActions
                  editPath={`/${listName}/edit/:id`}
                  viewPath={`/${listName}/view/:id`}
                  items={selectedMotivationContents}
                />
              )}
              onCreate={() => {
                ExternalRedirect({ to: `/${listName}/create`, target: "_blank" });
                setIsModalOpen(false);
              }}
              onSelect={(newMotivationContents: *): void => setSelectedMotivationContents((prev) => (
                multiple ? [...prev, newMotivationContents] : [newMotivationContents]
              ))}
              onDeselect={(item: *): void => setSelectedMotivationContents((prev) => (
                prev.filter(
                  (deselectItem: *): boolean => deselectItem.id !== item.id
                )
              ))}
            />
          )}
        />
      </GridUc>
      {value.length > 0 && (
        <GridUc item xs={8} mb={3}>
          <ViewList
            items={selectedMotivationContents}
            handleDelete={(id: string) => {
              const newChoiced: *[] = value.filter((item: *): boolean => item.id !== id);
              onChange && onChange([...newChoiced]);
            }}
          />
        </GridUc>
      )}
    </>
  );
}

export default ListModalSetup;
