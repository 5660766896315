/* eslint-disable import/max-dependencies */
/* eslint-disable react/require-default-props */
// @flow
import React, { useState } from "react";
import type { Node } from "react";
import {
  Box, Grow, IconButton, ClickAwayListener, Tabs, Tab,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AceEditor from "react-ace";
import type { AceEditor as AceEditorProps } from "react-ace";
import Beautify from "ace-builds/src-noconflict/ext-beautify";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-options";
import "ace-builds/src-noconflict/snippets/html";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/css";
import "ace-builds/src-noconflict/ext-emmet";
import type {
  UseState,
  EditorTab,
  Props,
  Classes,
  TabPanelProps,
} from "./CodeEditor.types";

const useStyles: () => Classes = makeStyles(() => ({
  fullscreenEditor: {
    position: "fixed",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    boxShadow: "0px 0px 0px 20rem rgb(0 0 0 / 60%)",
    zIndex: 10,
    width: "90vw",
    height: "80vh",
  },
  fullscreenBtn: {
    position: "absolute",
    zIndex: 5,
    color: "white",
    right: "1px",
  },
}));

function TabPanel({
  currentTabIndex,
  index,
  toggleFullScreen,
  height,
  sharedProps,
  classes,
  tab,
  ...other
}: TabPanelProps): Node {
  return (
    <Box
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={tab.name}
      position="relative"
      {...other}
    >
      {currentTabIndex === index && (
        <>
          <IconButton
            data-testid="fullscreen-btn"
            className={classes.fullscreenBtn}
            onClick={toggleFullScreen}
          >
            <FullscreenIcon />
          </IconButton>
          <AceEditor
            data-testid={tab.name}
            height={height}
            {...sharedProps}
            {...tab}
          />
        </>
      )}
    </Box>
  );
}

function CodeEditor({
  height,
  tabs = [],
}: Props): Node {
  const [isFullscreen, setIsFullscreen]: UseState<boolean> = useState(false);
  const [currentTabIndex, setCurrentTabIndex]: UseState<number> = useState(0);
  const classes: Classes = useStyles();

  const handleClickAway: () => void = () => {
    setIsFullscreen(false);
  };

  const toggleFullScreen: () => void = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleChangeTab: (*, number) => void = (_, index: number) => {
    setCurrentTabIndex(index);
  };

  const currentTab: EditorTab | {||} = tabs[currentTabIndex] || {};

  const sharedProps: AceEditorProps = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    fontSize: "1em",
    setOptions: { enableEmmet: true, useWorker: true },
    theme: "monokai",
    width: "100%",
    commands: Beautify.commands,
    showPrintMargin: false,
    debounceChangePeriod: 500,
  };

  return (
    <Box width="100%" border={1} borderRadius={2} borderColor="divider">
      <Box px={1} pb={1}>
        <Tabs value={currentTabIndex} onChange={handleChangeTab}>
          {tabs.map(({ name, label }: EditorTab, index: number): Node => (
            <Tab
              key={name}
              value={index}
              label={label.toUpperCase()}
              data-testid={`${name}-tab`}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab: EditorTab, index: number): Node => (
        <TabPanel
          key={tab.name}
          sharedProps={sharedProps}
          tab={tab}
          currentTabIndex={currentTabIndex}
          index={index}
          height={height}
          classes={classes}
          toggleFullScreen={toggleFullScreen}
        />
      ))}
      {isFullscreen && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Grow in={isFullscreen}>
            <Box className={classes.fullscreenEditor}>
              <AceEditor
                {...sharedProps}
                {...currentTab}
                name={`${currentTab.name || "default"}-fullscreen`}
                data-testid={`${currentTab.name || "default"}-fullscreen`}
                height="100%"
              />
            </Box>
          </Grow>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default CodeEditor;
