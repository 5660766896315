// @flow
import type { Node } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

type Props = { value?: *, check: (*) => boolean, children: Node };

function ShowByCondition(
  { check, children, value = "" }: Props
): Node {
  return check(value) && children;
}

export default withFormField(ShowByCondition);
