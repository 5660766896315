// @flow
import React, { type Node } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import AdditionalInfo from "../../components/AdditionalInfo";

type Props = {
  value?: *[],
}

function AdditionalInfoForm({
  value = [],
}: Props): Node {
  return <AdditionalInfo usedInCampaigns={value} />;
}

export default withFormField<*>(AdditionalInfoForm);
