// @flow
import React, { type Node } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  type StandardInputProps,
} from "@mui/material";

type Props = {
  label: string,
  name: string,
  value: string,
  error?: string,
  inputProps?: StandardInputProps,
  onChange: (string) => mixed,
  onUpload: () => mixed,
  classes?: { root?: string, uploadBtn?: string },
  disabled?: boolean,
  readOnly?: boolean,
}

function InputFile({
  label = "",
  name = "",
  value,
  inputProps = "",
  error = "",
  classes = {},
  disabled = false,
  readOnly = false,
  onChange,
  onUpload,
  ...props
}: Props): Node {
  const editable: boolean = !(disabled || readOnly);
  return (
    <TextField
      {...props}
      fullWidth
      className={classes.root}
      data-testid={name}
      label={label}
      name={name}
      size="small"
      error={Boolean(error)}
      helperText={error}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      }}
      InputProps={{
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              className={classes.uploadBtn}
              data-testid={`${name}-upload-btn`}
              variant="contained"
              size="small"
              onClick={onUpload}
              disabled={!editable}
            >
              Upload
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default InputFile;
