// @flow
import React from "react";
import { TextField, IconButton, Popover } from "@mui/material";
import { SentimentSatisfiedAlt } from "@mui/icons-material";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import Picker from "emoji-picker-react";
import { makeStyles } from "@mui/styles";
import getPipePosition from "../../helpers/getPipePosition";

type Props = {
  name: string,
  value?: string,
  error?: string,
  onChange?: (string) => *,
}

const useStyles = makeStyles({
  card: {
    overflow: "visible",
  },
  emojiBtn: {
    padding: 0,
  },
  inputContainer: {
    position: "relative",
  },
  emojisForm: {
    position: "absolute",
    zIndex: 1000,
    top: 50,
  },
  emojisIcon: {
    width: 25,
    height: 25,
  },
});

function EmojiTextFieldForm({
  value: inputValue = "",
  onChange,
  name = "",
  error = "",
  ...props
}: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleEmojiClick: (value: string) => void = (
    value: string
  ) => {
    const position: number | null = getPipePosition(name);

    if (position || position === 0) {
      const newValue: string = (
        inputValue.substring(position, 0) + value + inputValue.substring(position, inputValue.length)
      );
      onChange && onChange(newValue);
    }
  };

  return (
    <>
      <TextField
        {...props}
        id={name}
        value={inputValue}
        onChange={({ target: { value } }) => onChange && onChange(value)}
        size="small"
        margin="dense"
        variant="outlined"
        fullWidth
        data-testid={name}
        error={Boolean(error)}
        helperText={error}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                className={classes.emojiBtn}
                position="end"
                data-testid={`${name}-emojis-btn`}
                onClick={handleClick}
                size="large"
              >
                <SentimentSatisfiedAlt fontSize="large" />
              </IconButton>
              <Popover
                id={name}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Picker
                  onEmojiClick={(event, emoji): void => {
                    handleEmojiClick(emoji.emoji);
                    handleClose();
                  }}
                />
              </Popover>
            </>
          ),
        }}
      />
    </>
  );
}

export default withFormField<*>(EmojiTextFieldForm);
