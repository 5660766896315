// @flow
import React, { type Element } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  ConfirmTableActionButton,
} from "@fas/ui-core";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  Edit,
  DeleteOutline,
  Block,
  CheckCircleOutline,
  Visibility, FileCopy,
} from "@mui/icons-material";
import type { StoreWithTable, Item } from "./types/TablesActions.types";
import externalRedirect from "../../components/App/ExternalRedirect";
import { getFields } from "../../services/utils";

export type Props<T> = {
  tableKey: T,
  editPath: string,
  viewPath?: string,
  clonePath?: string,
  disableDelete?: boolean,
  onDelete: (string[]) => mixed,
  onChangeIsActive: (string[], boolean) => mixed,
};

function WebPushTablesActions<T>({
  tableKey,
  editPath,
  viewPath = "empty",
  clonePath = "empty",
  disableDelete = true,
  onDelete,
  onChangeIsActive,
}: Props<T>): Element<typeof TableActions> {
  const items: Item[] = useSelector((state: StoreWithTable): Item[] => getTableSelections(state, tableKey));
  const dispatch: <A>(A) => A = useDispatch();

  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={items.length > 1}
          onClick={(): null => externalRedirect({ to: editPath.replace(":id", items[0].id), target: "_blank" })}
          Icon={<Edit />}
        />
        <SimpleTableActionButton
          tooltip="View"
          disabled={viewPath === "empty" || items.length > 1}
          onClick={(): typeof undefined => {
            if (viewPath && viewPath !== "empty") {
              externalRedirect({ to: viewPath.replace(":id", items[0].id), target: "_blank" });
            }
          }}
          Icon={<Visibility />}
        />
        <SimpleTableActionButton
          tooltip="Clone"
          onClick={(): typeof undefined => {
            if (clonePath && clonePath !== "empty") {
              externalRedirect({ to: clonePath.replace(":id", items[0].id), target: "_blank" });
            }
          }}
          Icon={<FileCopy />}
          disabled={clonePath === "empty" || items.length > 1}
        />
        <ConfirmTableActionButton
          tooltip="Delete"
          items={items}
          disabled={disableDelete}
          confirmTitle="Deleting items"
          confirmText="Are you sure you want to delete"
          viewPath={viewPath}
          onClick={(): mixed => dispatch(onDelete(getFields(items, "id")))}
          Icon={<DeleteOutline />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <ConfirmTableActionButton
          tooltip="Deactivate"
          items={items}
          confirmTitle="Deactivating items"
          confirmText="Are you sure you want to deactivate "
          viewPath={viewPath}
          onClick={(): mixed => dispatch(onChangeIsActive(getFields(items, "id"), false))}
          Icon={<Block />}
        />
        <ConfirmTableActionButton
          tooltip="Activate"
          items={items}
          confirmTitle="Activating items"
          confirmText="Are you sure you want to activate "
          viewPath={viewPath}
          onClick={(): mixed => dispatch(onChangeIsActive(getFields(items, "id"), true))}
          Icon={<CheckCircleOutline />}
        />
      </TableActionGroup>
    </TableActions>
  );
}

export default WebPushTablesActions;
