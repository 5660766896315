// @flow
import React, { type Element } from "react";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import { Edit, Visibility } from "@mui/icons-material";
import type { Item } from "./types/TablesActions.types";
import externalRedirect from "../../components/App/ExternalRedirect";

export type Props = {
  editPath: string,
  viewPath?: string,
  items: Array<Item>,
};

function StepTablesActions({
  editPath,
  viewPath = "empty",
  items,
}: Props): Element<typeof TableActions> {
  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={items.length > 1}
          onClick={(): null => externalRedirect({ to: editPath.replace(":id", items[0].id), target: "_blank" })}
          Icon={<Edit />}
        />
        <SimpleTableActionButton
          tooltip="View"
          disabled={viewPath === "empty" || items.length > 1}
          onClick={(): typeof undefined => {
            if (viewPath && viewPath !== "empty") {
              externalRedirect({ to: viewPath.replace(":id", items[0].id), target: "_blank" });
            }
          }}
          Icon={<Visibility />}
        />
      </TableActionGroup>
    </TableActions>
  );
}

export default StepTablesActions;
