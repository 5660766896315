// @flow
import React from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { Counter } from "@fas/ui-core";

type Props = {
  name: string,
  value?: number,
  min: number,
  max: number,
  onChange?: (number) => *,
}

const defaultOnChange = () => {};

function CounterForm({
  value = 0,
  onChange = defaultOnChange,
  ...props
}: Props) {
  return <Counter {...props} counter={value} onChange={onChange} />;
}

export default withFormField<*>(CounterForm);
